import React, { useEffect, useState } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';

import { Button, Navbar } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel, LayersSection, TextSection, ElementsSection, UploadSection } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { createStore } from 'polotno/model/store';
import { UploadPanel } from './Utils/UploadPanel';
import { GenerateSection } from './Utils/GenerateSection';
import { getProject, updateProjectDesign } from './Utils/get';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const store = createStore({
  key: process.env.REACT_APP_POLOTNO_API_KEY,
  showCredit: false,
});

UploadSection.Panel = UploadPanel;

const EditorComponent = () => {
  const { slug } = useParams();
  const [designSaving, setDesignSaving] = useState(false);
  const [name, setName] = useState("Artwand Flashcard Studio");
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      getProject(slug).then((response) => {
        setName(response.name);
        const dataJson = response.dataJson;
        if (dataJson) {
          store.loadJSON(dataJson);
        } else {
          store.setSize(396.85039370078744, 566.9291338582677);
          store.addPage();
        }
        setIsPageLoading(false);
      });
    } else {
      store.setSize(396.85039370078744, 566.9291338582677);
      store.addPage();
    }
  }, [slug]);

  useEffect(() => {
    if (!isPageLoading) {
      if (isMobile) {
        const firstTab = document.querySelector('.polotno-side-panel-tab');
        if (firstTab) {
          firstTab.click();
        }
      }
    }
  }, [isPageLoading]);

  const handleClose = async () => {
    await handleSave();
    window.location.href = "/";
  };

  const handleSave = async () => {
    setDesignSaving(true);
    
    // Convert store to JSON
    const dataJson = store.toJSON();

    // Convert the first page to an image
    let coverImage = null;
    if (store.pages.length > 0) {
      coverImage = await store.toDataURL({ pageId: store.pages[0].id });
    }

    // Save to Firebase
    try {
      await updateProjectDesign(slug, dataJson, coverImage);
      setDesignSaving(false);
    } catch (error) {
      console.error("Error saving design: ", error);
      setDesignSaving(false);
    }
  };

  const handleExportPDF = async () => {
    setDesignSaving(true);
    for (let i = 0; i < store.pages.length; i++) {
      await store.saveAsPDF({ fileName: 'Artwand.png', pageId: store.pages[i].id });
    }
    setDesignSaving(false);
  };
  
  const handleExportPNG = async () => {
    setDesignSaving(true);
    for (let i = 0; i < store.pages.length; i++) {
      await store.saveAsImage({ fileName: 'Artwand.png', pageId: store.pages[i].id });
    }
    setDesignSaving(false);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar>
        <Navbar.Group>
          <Navbar.Heading>{name}</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align="right">
          <Button variant="outline-dark" size="sm" icon="document" text="Export PDF" onClick={handleExportPDF} className='m-1' disabled={designSaving} loading={designSaving}/>
          <Button variant="outline-dark" size="sm" icon="media" text="Export PNG" onClick={handleExportPNG} className='m-1' disabled={designSaving} loading={designSaving}/>
          <Button variant="outline-dark" size="sm" icon="cross" text="Close" onClick={handleClose} className='m-1' disabled={designSaving} loading={designSaving}/>
        </Navbar.Group>
      </Navbar>
      {isPageLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="text-center">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading flashcards, please wait...</p>
          </div>
        </div>
      ) : (
        <PolotnoContainer style={{ flex: 1, width: '100vw' }}>
          <SidePanelWrap>
            <SidePanel store={store} sections={[GenerateSection, LayersSection, TextSection, ElementsSection, UploadSection]} defaultSection="generate" />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
        )}
    </div>
  );
};

export default EditorComponent;
import React from 'react';
import HeaderMenu from './Header';

const Subscriptions = () => (
  <div>
    <HeaderMenu />
    <div className="p-3" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
      <div style={subscriptionOptionStyle}>
        <h2 style={{ ...headerStyle, backgroundColor: '#3498db' }}>Monthly</h2>
        <div style={priceStyle}>
          <span style={currencyStyle}>$</span>
          <span style={amountStyle}>5</span>
          <br />
          <span style={periodStyle}>per month</span>
        </div>
        <div style={featuresStyle}>
          <p>Unlimited Flashcards</p>
          <hr />
          <p>Unlimited Export</p>
        </div>
        <button style={{ ...buttonStyle, backgroundColor: '#3498db' }}>Get Started</button>
      </div>
      <div style={subscriptionOptionStyle}>
        <h2 style={{ ...headerStyle, backgroundColor: '#e74c3c' }}>Annually</h2>
        <div style={priceStyle}>
          <span style={currencyStyle}>$</span>
          <span style={amountStyle}>2</span>
          <br />
          <span style={periodStyle}>per month</span>
        </div>
        <div style={featuresStyle}>
          <p>Unlimited Flashcards</p>
          <hr />
          <p>Unlimited Export</p>
        </div>
        <button style={{ ...buttonStyle, backgroundColor: '#e74c3c' }}>Get Started</button>
      </div>
    </div>
  </div>
);

const subscriptionOptionStyle = {
  backgroundColor: '#2c3e50',
  color: 'white',
  borderRadius: '8px',
  overflow: 'hidden',
  width: '300px',
  textAlign: 'center',
};

const headerStyle = {
  margin: '0',
  padding: '20px',
};

const priceStyle = {
  backgroundColor: '#34495e',
  padding: '20px',
  fontSize: '24px',
};

const currencyStyle = {
  fontSize: '18px',
};

const amountStyle = {
  fontSize: '48px',
};

const periodStyle = {
  fontSize: '0.75rem',
};

const featuresStyle = {
  backgroundColor: '#2c3e50',
  padding: '20px',
};

const buttonStyle = {
  border: 'none',
  color: 'white',
  padding: '15px',
  width: '100%',
  cursor: 'pointer',
  fontSize: '18px',
};

export default Subscriptions;
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import EditorComponent from './Editor';
import Home from './Home';
import Register from './Register';
import StartDesign from './StartDesign';
import Subscriptions from './Subscriptions';
function App() {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('S:User');
        if (!user) {
            navigate('/register');
        }
    }, [navigate]);

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/editor/:slug" element={<EditorComponent />} />
            <Route path="/register" element={<Register />} />
            <Route path="/start" element={<StartDesign />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
        </Routes>
    );
}

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}
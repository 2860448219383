// src/GenerateSection.js
import React, { useState } from 'react';
import { Button, TextArea, Icon, FormGroup } from '@blueprintjs/core';
import { SectionTab } from 'polotno/side-panel';

const GeneratePanel = ({ store }) => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);

  const generate_style_1 = async () => {
    store.activePage.set({
      width: 396.85039370078744,
      height: 566.9291338582677,
      unit: "cm"
    });

    const response = await fetch('https://ybfy2j5r7bhkvbm5mbbjwu6rr40rheba.lambda-url.eu-west-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "action": "generator", prompt }),
    });
    const data = await response.json();
    const imageUrl = data.src;
    const title = data.title;
    const imagePrompt = data.imagePrompt

    store.activePage.addElement({
      "type": "svg",
      "name": "background",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": -5.084821452783217e-14,
      "y": 0.17638687690931842,
      "width": 397.1022558319716,
      "height": 566.7527469813584,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "src": "https://images.artwand.ai/card-style-1.svg",
      "maskSrc": "",
      "cropX": 0,
      "cropY": 0,
      "cropWidth": 1,
      "cropHeight": 1,
      "keepRatio": true,
      "flipX": false,
      "flipY": false,
      "borderColor": "black",
      "borderSize": 0,
      "cornerRadius": 0,
      "colorsReplace": {},
      "custom": {}
    });

    store.activePage.addElement({
      "type": "image",
      "name": "",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": 43.35916289836888,
      "y": 124.2051092931591,
      "width": 310.3839300352336,
      "height": 413.8452400469783,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "src": imageUrl,
      "cropX": 0,
      "cropY": 0,
      "cropWidth": 0.9999999999999997,
      "cropHeight": 1,
      "cornerRadius": 0,
      "flipX": false,
      "flipY": false,
      "clipSrc": "data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiPgogICAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtMC45MTk0NzgyODY2MTM0ODEyIj4KICAgICAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiAvPgogICAgPC9jbGlwUGF0aD4KICA8L2RlZnM+CgogIDwhLS0gUGF0aCBmb3IgdGhlIGZpbGwgLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJsaWdodGdyYXkiIHRyYW5zZm9ybT0ic2NhbGUoMSwgMSkiLz4KCiAgPCEtLSBQYXRoIGZvciB0aGUgc3Ryb2tlLCBjbGlwcGVkIGJ5IHRoZSBzdGFyIHBhdGggLS0+CiAgPHBhdGggZD0iTSAwIDAgTCAzMDAgMCBMIDMwMCAzMDAgTCAwIDMwMCBaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwYzBjMGMiIHN0cm9rZS13aWR0aD0iMCIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtMC45MTk0NzgyODY2MTM0ODEyKSIgdHJhbnNmb3JtPSJzY2FsZSgxLCAxKSIgc3Ryb2tlLWRhc2hhcnJheT0iIi8+CiAgICA8L3N2Zz4KICA=",
      "borderColor": "black",
      "borderSize": 0,
      "keepRatio": false,
      "custom": {"prompt": imagePrompt}
    });

    store.activePage.addElement({
      "type": "text",
      "name": "title",
      "opacity": 1,
      "visible": true,
      "selectable": true,
      "removable": true,
      "alwaysOnTop": false,
      "showInExport": true,
      "x": 21.35965398691114,
      "y": 49.83409116113936,
      "width": 357,
      "height": 42,
      "rotation": 0,
      "animations": [],
      "blurEnabled": false,
      "blurRadius": 10,
      "brightnessEnabled": false,
      "brightness": 0,
      "sepiaEnabled": false,
      "grayscaleEnabled": false,
      "shadowEnabled": false,
      "shadowBlur": 5,
      "shadowOffsetX": 0,
      "shadowOffsetY": 0,
      "shadowColor": "black",
      "shadowOpacity": 1,
      "draggable": true,
      "resizable": true,
      "contentEditable": true,
      "styleEditable": true,
      "text": title,
      "placeholder": "",
      "fontSize": 34,
      "fontFamily": "Roboto",
      "fontStyle": "normal",
      "fontWeight": "normal",
      "textDecoration": "",
      "fill": "rgba(255,255,255,1)",
      "align": "center",
      "verticalAlign": "top",
      "strokeWidth": 0,
      "stroke": "black",
      "lineHeight": 1.2,
      "letterSpacing": 0,
      "backgroundEnabled": false,
      "backgroundColor": "#7ED321",
      "backgroundOpacity": 1,
      "backgroundCornerRadius": 0.5,
      "backgroundPadding": 0.5,
      "custom": {}
    });

    setLoading(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      // Clear all elements in activePage
      await flushPage();

      generate_style_1();
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const flushPage = async () => {
    store.activePage.set({ children: [] });
  };


  return (
    <div style={{ padding: '16px' }}>
      <div className='text-dark mb-3'><strong>Flashcard Generator</strong></div>
      <FormGroup
        label={
            <span className="font-small ps-1">Image Prompt</span>
          }
        labelFor="prompt-textarea"
        className="font-small"
      >
        <TextArea
          id="prompt-textarea"
          fill
          growVertically
          large
          placeholder="Describe your image in the flashcard."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className='font-small'
          style={{ borderRadius: '10px' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleGenerate();
            }
          }}
        />
      </FormGroup>
      <Button
        text="Generate"
        className='brand-button-outline'
        onClick={handleGenerate}
        loading={loading}
        style={{ marginTop: '16px', borderRadius: '9999px' }}
      />
    </div>
  );
};

export const GenerateSection = {
  name: 'generate',
  Tab: (props) => (
    <SectionTab name="Generate" {...props}>
      <Icon icon="media" />
    </SectionTab>
  ),
  Panel: GeneratePanel,
};
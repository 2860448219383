import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { createProject, listProjects, deleteProject } from './Utils/get';
import moment from 'moment';

const ListingContainer = () => {
  const [showModal, setShowModal] = useState(false);
  const [flashCardName, setFlashCardName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true); 

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsList = await listProjects();
      setProjects(projectsList);
      setIsPageLoading(false);
    };

    fetchProjects();
  }, []);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleSave = async () => {
    setIsLoading(true);
    const projectId = await createProject({ name: flashCardName });

    setShowModal(false);
    window.location.href = `/editor/${projectId}`;
  };

  const handleDelete = async () => {
    if (projectToDelete) {
      await deleteProject(projectToDelete);
      setProjects(projects.filter(project => project.id !== projectToDelete));
      setShowDeleteModal(false);
      setProjectToDelete(null);
    }
  };

  const confirmDelete = (projectId) => {
    setProjectToDelete(projectId);
    setShowDeleteModal(true);
  };

  return (
    <Container fluid className="p-4">
      {isPageLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="text-center">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading projects, please wait...</p>
          </div>
        </div>
      ) : (
        <>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <Button onClick={handleShow} className="ms-auto brand-button-outline d-flex align-items-center">
                <FaPlus className="me-2" />
                New Flash Card
              </Button>
            </Col>
          </Row>
          {projects.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              <Card className="text-center p-4" style={{ width: '100%'}}>
                <Card.Body>
                  <Card.Text>
                    <p>It looks like you don't have any flashcard projects yet. 
                    Click the button below to create your first flashcard project!</p>
                  </Card.Text>
                  <Button onClick={handleShow} className="brand-button-outline d-flex align-items-center mx-auto">
                    <FaPlus className="me-2" />
                    Create New Flash Card Project
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <Row>
              {projects.map((project) => (
                <Col key={project.id} xs={12} md={4}>
                  <Card className='mb-3'>
                    <Card.Img style={{objectFit: "contain", maxHeight: "200px"}} variant="top" src={project.coverImage} className="card-img-top"/>
                    <Card.Body>
                      <Card.Title>{project.name}</Card.Title>
                      <Card.Text className="text-muted font-small">
                        Created {moment(project.created_at).fromNow()} and updated {moment(project.updated_at).fromNow()}.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                      <Button size="sm" variant="outline-danger" onClick={() => confirmDelete(project.id)} className='me-2'>Delete</Button>
                      <Button size="sm" className="brand-button-outline" onClick={() => window.location.href = `/editor/${project.id}`}>Open</Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFlashCardName">
              <Form.Label className='font-small ps-1'>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a flashcard project name"
                value={flashCardName}
                onChange={(e) => setFlashCardName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='brand-button' onClick={handleSave} disabled={isLoading}>
            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this project?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ListingContainer;
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { signOutUser } from './firebase';

const HeaderMenu = () => (
  <Navbar expand="lg" variant="dark" style={{ backgroundColor: '#612dae' }} className='p-2'>
    <div className="container-fluid d-flex justify-content-between align-items-center">
      <Navbar.Brand href="/" style={{ color: '#fff' }}>
          <img src="/images/artwand-logo-white.png" alt="Artwand Logo" className='ms-2' style={{ maxHeight: '50px' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: '#fff' }} className="ms-auto" />
    </div>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ms-auto">
        <Nav.Link href="/subscriptions" style={{ color: '#fff' }}>Pricing</Nav.Link>
        <Nav.Link href="#" onClick={signOutUser} style={{ color: '#fff' }}>Logout</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default HeaderMenu;